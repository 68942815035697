import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import { ContentAlign } from '../../layout/mixins'
import styled from 'styled-components'

const Content = styled(ContentAlign)`
  padding-top: ${({ theme }) => theme.gutterXXLarge};
  padding-bottom: ${({ theme }) => theme.gutterXXLarge};
`

const SingleArticle = ({ data, path }) => {
  const {
    title,
    textContent
  } = data?.content

  return (
    <Layout progressBar={true}>
      <Hero 
        indentTitle={title}
       />
       <section>
         <Content className="rte" dangerouslySetInnerHTML={{ __html: textContent }} />
       </section>
    </Layout>
  )
}

export default SingleArticle

export const query = graphql`
  query SinglePageQuery($slug: String!) {
    content: datoCmsPage(slug: {eq: $slug}) {
      title
      textContent
    }
  }
`
